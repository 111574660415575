<template>
  <header>
    <div id="brand">
      <!-- logo -->
      <nuxt-link
        id="brand-title"
        to="/"
        rel="author"
      >
        Jarik Marwede
      </nuxt-link>
      <button v-show="!bigScreen" class="icon-button" :title="showNavbar ? 'Close navigation bar' : 'Extend navigation bar'" @click="showNavbar = !showNavbar">
        <font-awesome-icon
          id="navbar-toggle-icon"
          :icon="currentNavbarIcon"
        />
      </button>
    </div>
    <nav
      v-if="showNavbar || bigScreen"
      class="slide-down"
    >
      <nuxt-link
        v-for="route in [{name: 'home', url: '/'}, {name: 'projects', url: '/projects'}, {name: 'about', url: '/about'}, {name: 'contact', url: '/contact'}]"
        :id="route.name + '-link'"
        :key="route.name"
        :to="route.url"
        @click.native="showNavbar = false"
      >
        {{ $t(route.name + '-link') }}
      </nuxt-link>
    </nav>
    <div
      v-show="showNavbar || bigScreen"
      id="right-buttons"
      class="slide-down"
    >
      <button id="language-button" class="icon-button" :aria-label="$t('change-language') + notSelectedLanguage" @click="toggleLanguage">
        <img
          id="flag-icon"
          alt=""
          :src="currentFlag"
        >
      </button>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      bigScreen: false,
      showNavbar: false,
      germanFlag: require("../assets/flags/de.svg"),
      britishFlag: require("../assets/flags/gb.svg")
    };
  },
  computed: {
    notSelectedLanguage() {
      return this.$root.$i18n.locale === "en" ? "de" : "en";
    },
    currentFlag() {
      if (this.$root.$i18n.locale === "en") {
        return this.germanFlag;
      } else {
        return this.britishFlag;
      }
    },
    currentNavbarIcon() {
      if (this.showNavbar) {
        return "times";
      } else {
        return "bars";
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleLanguage() {
      this.showNavbar = false;

      if (this.$i18n.locale === "en") {
        this.setLanguageCookie("de");
      } else {
        this.setLanguageCookie("en");
      }
    },
    setLanguageCookie(languageCode) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + (365 * 24 * 60 * 60 * 1000));

      document.getElementsByTagName("html")[0].lang = languageCode;
      this.$root.$i18n.locale = languageCode;
      document.cookie = `lang=${languageCode};expires=${expirationDate.toUTCString()};path=/;SameSite=Strict`;
    },
    handleResize() {
      this.bigScreen = window.innerWidth >= 992;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "home-link": "Home",
    "projects-link": "Projects",
    "about-link": "About Me",
    "contact-link": "Contact Me",
    "change-language": "Change the language to: "
  },
  "de": {
    "home-link": "Home",
    "projects-link": "Projekte",
    "about-link": "Über Mich",
    "contact-link": "Kontakt",
    "change-language": "Sprache ändern zu: "
  }
}
</i18n>

<style scoped>
@keyframes slide-down {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

header {
  background-color: var(--dark-primary-color);
  color: white;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
}
.slide-down {
  animation-name: slide-down;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
#brand {
  margin-left: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
#brand-title {
  padding: 1rem 0;
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "kaushan_scriptregular", "Times New Roman", Times, serif;
}
#navbar-toggle-icon {
  width: 30px;
  height: 30px;
}
nav {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: 1rem 0;
}
nav a {
  padding: 2rem;
  font-size: 1.25rem;
  font-family: "titilliumregular", Tahoma, Geneva, sans-serif;
  color: white;
}
nav a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
nav .nuxt-link-exact-active,
nav .nuxt-link-active:not(#home-link) {
  background-color: rgba(0, 0, 0, 0.1);
}
#right-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 1rem;
}
#language-button {
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  width: 40px;
  height: 40px;
}
#language-button > img {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  header {
    flex-flow: row nowrap;
    align-items: center;
  }
  #brand-title {
    font-size: 2rem;
  }
  nav {
    flex-flow: row nowrap;
    margin: 0;
    animation-name: none !important;
  }
  #right-buttons {
    justify-content: flex-end;
    margin-right: 2rem;
    margin-bottom: 0;
    animation-name: none;
  }
}
</style>
