<template>
  <div id="error">
    <h1 id="error-message">
      {{ errorMessage }}
    </h1>
    <p id="error-description">
      {{ errorDescription }}
    </p>
    <nuxt-link to="/">
      {{ $t("back-to-home") }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    errorMessage() {
      if (this.error.statusCode === 404) {
        return this.$t("404-error-message");
      } else if (this.error.statusCode === 410) {
        return this.$t("410-error-message");
      }
      return this.$t("unknown-error-message");
    },
    errorDescription() {
      if (this.error.statusCode === 404) {
        return this.$t("404-error-description");
      } else if (this.error.statusCode === 410) {
        return this.$t("410-error-description");
      }
      return this.$t("unknown-error-description");
    }
  },
  head() {
    return {
      title: this.errorMessage
    };
  }
};
</script>

<i18n>
{
  "en": {
    "404-error-message": "404 Page not found!",
    "404-error-description": "The Page you wanted to go to does not exist (anymore). Perhaps you misspelled something? Or the page got deleted.",
    "410-error-message": "410 Page is gone!",
    "410-error-description": "The Page you wanted to go to was removed",
    "unknown-error-message": "Unkown error!",
    "unknown-error-description": "An unkown error occured",
    "back-to-home": "Back to the Home page"
  },
  "de": {
    "404-error-message": "404 Seite existiert nicht!",
    "404-error-description": "Die Seite, auf die du gehen wolltest existiert nicht (mehr). Vielleicht hast du dich vertippt? Oder die Seite wurde entfernt.",
    "410-error-message": "410 Seite fehlt!",
    "410-error-description": "Die Seite, die du besuchen wolltest wurde entfernt",
    "unknown-error-message": "Unbekannter Fehler!",
    "unknown-error-description": "Ein unbekannter Fehler ist aufgetreten",
    "back-to-home": "Zurück zur Startseite"
  }
}
</i18n>

<style scoped>
  #error {
    display: flex;
    flex-flow: column wrap;
  }
  #error-message {
    text-align: center;
    font-size: 3rem;
  }
  #error-description {
    font-size: 1.5rem;
    margin: 1rem 2rem;
  }
  a {
    text-align: center;
    font-size: 2rem;
    margin: 1rem 0;
  }

  @media only screen and (min-width: 1200px) {
    #error-description {
      text-align: left;
      font-size: 2rem;
      margin: 1rem 25vw 1rem 25vw;
    }
  }
</style>
