<template>
  <div id="app">
    <app-header />
    <main>
      <nuxt />
    </main>
    <app-footer />
  </div>
</template>

<script>
import header from "../components/header.vue";
import footer from "../components/footer.vue";

export default {
  components: {
    "app-header": header,
    "app-footer": footer
  },
  mounted() {
    this.loadLanguage();
  },
  methods: {
    loadLanguage() {
      const languageCookie = this.readCookie("lang");

      if (languageCookie === "de") {
        this.$root.$i18n.locale = "de";
      } else if (languageCookie === "en") {
        this.$root.$i18n.locale = "en";
      } else if (navigator.language === "de") {
        this.$root.$i18n.locale = "de";
      } else {
        this.$root.$i18n.locale = "en";
      }
    },
    readCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    };
  }
};
</script>

<style>
@import "../assets/fonts/fonts.css";

:root {
  --primary-color: #E52524;
  --dark-primary-color: #BF1F1E;
  --email-btn-color: #6f6f6f;
  /* color can not be the same as twitters because contrast is too low */
  --twitter-btn-color: rgb(0, 119, 181);
  /* color can not be the same as telegrams because contrast is too low */
  --telegram-btn-color: #4a7290;
  --github-btn-color: #24292e;
  --codepen-btn-color: rgb(25, 26, 29);
  --linkedin-btn-color: rgb(0, 119, 181);
  --xing-btn-color: #007575;
}

/* rules for styling the browser-update popup */
body #buorgul {
  background-color: #3f8201;
}
body #buorgig {
  background-color: #990202;
}

/* rules for styling the vue-toasted toasts */
.toasted {
  padding: 0.5rem 1rem;
}
.toasted > * {
  margin: 0.5rem;
}
.toasted a {
  color: white !important;
}
.toasted.primary.error {
  background-color: var(--dark-primary-color) !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  font-weight: bold;
  font-family: "titilliumregular", Tahoma, Geneva, sans-serif;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.4rem;
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: underline;
}
p, a, span, time, address, summary {
  font-family: "droid_sansregular", Tahoma, Geneva, sans-serif;
}
p {
  color: #333333;
}
button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: white;
  font-size: 1.1rem;
  margin: 0.5rem 1rem;
  padding: 0.8rem;
}
button:hover, button:focus {
  background-color: var(--dark-primary-color);
}
input, textarea {
  background-color: rgba(0, 0, 0, 0.03);
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.15);
  padding: 0.7rem;
  resize: none;
}

.link-button {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin: 0.5rem 1rem;
  text-align: center;
  font-size: 1.1rem;
  color: white;
  user-select: none;
}
.link-button:hover {
  background-color: white !important;
  color: var(--primary-color);
  text-decoration: none;
}
.icon-button {
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  background: none;
}
.responsive-text {
  margin: 0 6vw;
}
.responsive-text p {
  line-height: 1.6;
  font-size: 1.3rem;
}
.card {
  border-radius: 20px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}
.email-button {
  background-color: var(--email-btn-color);
  border: 2px solid var(--email-btn-color);
}
.email-button:hover {
  color: var(--email-btn-color);
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
main {
  display: flex;
  flex: 1;
}
main > * {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .responsive-text {
    margin: 0 25vw;
  }
}
</style>
