<template>
  <div v-if="loading">
    <div id="shadow" />
    <font-awesome-icon
      id="loading-icon"
      icon="spinner"
      size="3x"
      spin
      fixed-with
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    };
  },
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
  #shadow {
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
  #loading-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: var(--primary-color);
  }
</style>
