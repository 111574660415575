import Vue from "vue";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTimes, faAngleDoubleDown, faAngleDoubleUp, faSpinner, faExclamationTriangle, faReply, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faTwitter, faGithub, faLinkedinIn, faXing, faCodepen, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

config.autoAddCss = false;

library.add(faBars, faTimes, faAngleDoubleDown, faAngleDoubleUp, faSpinner, faExclamationTriangle, faReply, faThumbsUp, faEnvelope, faCalendarAlt, faTwitter, faGithub, faLinkedinIn, faXing, faCodepen, faTelegramPlane);

Vue.component("font-awesome-icon", FontAwesomeIcon);
