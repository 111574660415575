<template>
  <footer>
    <div id="link-wrapper">
      <div
        id="site-links"
        class="links"
      >
        <p class="link-header">
          Links
        </p>
        <ul>
          <li>
            <nuxt-link to="/">
              {{ $t("home") }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/projects">
              {{ $t("projects") }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/about">
              {{ $t("about") }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/contact">
              {{ $t("contact") }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div
        id="project-links"
        class="links"
      >
        <p class="link-header">
          {{ $t("projects") }}
        </p>
        <ul>
          <li>
            <a href="https://github.com/jarikmarwede/IdeaBag2-Solutions">IdeaBag2 Solutions</a>
          </li>
          <li>
            <a href="https://jarikmarwede.github.io/freeCodeCamp-projects/">freecodeCamp Projects</a>
          </li>
        </ul>
      </div>
    </div>
    <ul id="contact-links">
      <li
        v-for="item in links"
        :key="item.link"
      >
        <a
          :href="item.link"
          :aria-label="item.ariaLabel"
          rel="me"
          @mouseover="item.hovered = true"
          @mouseleave="item.hovered = false"
        >
          <font-awesome-icon
            :icon="item.icon"
            :spin="item.hovered"
          />
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
import {
  CODEPEN_PROFILE,
  EMAIL_ADDRESS,
  GITHUB_PROFILE,
  LINKEDIN_PROFILE,
  TELEGRAM_PROFILE,
  TWITTER_PROFILE,
  XING_PROFILE
} from "assets/js/constants";

export default {
  data() {
    return {
      links: {
        email: {
          link: `mailto:${EMAIL_ADDRESS}`,
          icon: ["far", "envelope"],
          ariaLabel: "Message me via email",
          hovered: false
        },
        twitter: {
          link: TWITTER_PROFILE,
          icon: ["fab", "twitter"],
          ariaLabel: "Message me on Twitter",
          hovered: false
        },
        telegram: {
          link: TELEGRAM_PROFILE,
          icon: ["fab", "telegram-plane"],
          ariaLabel: "Message me on Telegram",
          hovered: false
        },
        linkedin: {
          link: LINKEDIN_PROFILE,
          icon: ["fab", "linkedin-in"],
          ariaLabel: "Message me on LinkedIn",
          hovered: false
        },
        xing: {
          link: XING_PROFILE,
          icon: ["fab", "xing"],
          ariaLabel: "Message me on Xing",
          hovered: false
        },
        github: {
          link: GITHUB_PROFILE,
          icon: ["fab", "github"],
          ariaLabel: "Look at my code on Github",
          hovered: false
        },
        codepen: {
          link: CODEPEN_PROFILE,
          icon: ["fab", "codepen"],
          ariaLabel: "Look at my CodePen projects",
          hovered: false
        }
      }
    };
  }
};
</script>

<i18n>
{
  "en": {
    "home": "Home",
    "projects": "Projects",
    "about": "About Me",
    "contact": "Contact Me"
  },
  "de": {
    "home": "Home",
    "projects": "Projekte",
    "about": "Über Mich",
    "contact": "Kontakt"
  }
}
</i18n>

<style scoped>
footer {
  background-color: var(--dark-primary-color);
  color: white;
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;
}
footer p, footer a {
  color: white;
  font-family: "titilliumregular", Tahoma, Geneva, sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#link-wrapper {
  display: flex;
  flex-flow: row wrap;
}
.links {
  margin: 0 1rem;
  display: flex;
  flex-flow: column nowrap;
}
.links ul > li {
  margin: 0.5rem 0;
}
.link-header {
  font-weight: bold;
}
#contact-links {
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}
#contact-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.3);
  background-color: white;
}
#contact-links a:hover {
  color: var(--primary-color);
}

@media only screen and (min-width: 992px) {
  #contact-links {
    justify-content: space-between;
  }
}
</style>
